import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import "./PostListNavigation.scss";
import { useIsClient } from "../../../hooks";

export const PostListNavigation = ({ categories }) => {
  if (!categories) return null;
  const [location, setLocation] = useState("");
  useEffect(() => {
    if (useIsClient) {
      setLocation(
        window?.location?.pathname
          ?.split("/")
          .filter((x) => isNaN(x)) // Remove page number numbers
          .filter((p) => p !== "page") // Remove page
          .filter((n) => n)
          .pop()
      );
    }
  }, [location]);

  const handleChange = (path) => {
    if (!path) return null;
    if (path === "news") {
      navigate(`/news/`);
    } else {
      navigate(`/category/${path}/`);
    }
  };

  return (
    <section className="post-list-navigation layout">
      <div className="post-list-navigation-container">
        <div className="filter-container">
          <label className="filter-label" htmlFor="filter">
            Filter:
          </label>
          <select
            className="filter-select"
            name="filter"
            id="filter"
            value={location}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="news">All</option>
            {categories.length > 0 &&
              categories.map(
                ({ node: category }, index) =>
                  category.slug !== "uncategorized" && (
                    <option key={index} value={category.slug}>
                      {category.name}
                    </option>
                  )
              )}
          </select>
        </div>
      </div>
    </section>
  );
};
